import React,{ useState } from 'react';

import { Button } from '../ui/button';
import { db } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from "firebase/storage";



const AddScientificCorner = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    doctor: '',
    date: '',
    type: '',
    description: ''
  });



  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submit = () => {
    //check if all fields are filled
    if (Object.values(formData).some(v => v.trim() === '')) {
      setIsError(true);
      return;
    }
    if(uploadFile == null){
      setIsError(true);
      return;
    }
    
    setIsError(false);
    pushImgToBucket();
   
  };

  const pushImgToBucket = () => {
    const storage = getStorage();
    const timestamp = Date.now();

    const storageRef = ref(storage, timestamp+"_"+uploadFile.name);

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, uploadFile).then((snapshot) => {
      sendToFirebase({...formData,url:timestamp+"_"+uploadFile.name})
    });
  }


  const sendToFirebase = async data => {
    try {
      setLoading(true);
      setIsError(false);
      setIsSuccess(false);

      const scientificCornerRef = collection(db, 'scientific_corner');

          const docRef = await addDoc(scientificCornerRef, {
              title: data.title,
              doctor: data.doctor,
              date: data.date,
              type: data.type,
              url: data.url,
              description: data.description

            });
            navigate('/admin/scientific-corner');
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setLoading(false);
    }
  };

  const handleChangeImg=(event)=>{
    const file = event.target.files[0];
    setUploadFile(file);
  }

  return (
    <div className=" h-screen main-screen-width  p-5">
    <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Title</div>
        <input
          onChange={handleChange}
          placeholder="Insert Title"
          name="title"
          value={formData.title}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Doctor</div>
        <input
          placeholder="Insert Doctor"
          name="doctor"
          value={formData.doctor}

          onChange={handleChange}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      </div>  
      <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Type</div>
        <select
          onChange={handleChange}
          name="type"
          value={formData.type}
          className=" p-3 rounded-lg border w-full focus:outline-none"
        >
          <option value="">Select Type</option>
          <option value="Physiotherapy">Physiotherapy</option>
          <option value="Nutrition">Nutrition</option>
          <option value="Family Medicine">Family Medicine</option>
          <option value="Women’s Health">Women’s Health</option>
          <option value="Body Image">Body Image</option>
          <option value="Feminine Adventure">Feminine Adventure</option>
          <option value="Emotional Dependance">Emotional Dependance</option>
          <option value="Chronic Pain">Chronic Pain</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Date</div>
        <input
          onChange={handleChange}
          placeholder="Insert Date"
          name="date"
          type={'date'}
          value={formData.date}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      
      
      </div>
      <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-[46rem] '>
        <div className="B1 font-semibold pb-1">Description</div>
        <textarea
        rows={10}
          placeholder="Description"
          name="description"
          value={formData.description}

          onChange={handleChange}
          className="p-3 border rounded-lg w-full focus:outline-none"
        />
      </div>
      </div>
      <div className=" flex justify-center space-x-4 w-full  p-5">
      <div className='w-1/3'>
        <div className="B1 font-semibold pb-1">Img</div>
       <input type='file' onChange={handleChangeImg}/>
      </div>
      <div className='w-1/3'>
        <img className='w-64' src={uploadFile instanceof File?URL.createObjectURL(uploadFile):''} />
      </div>
      
      
      </div>
      <div className=" flex justify-end space-x-4 w-full  p-5">
      <div className='w-1/3'></div>
      <div className='w-1/3'>
      <div className="  w-full space-x-4">
        <Button className='bg-red-500 hover:bg-red-400' onClick={()=>navigate('/admin/scientific-corner')} >
          Cancel
        </Button>
        <Button onClick={submit} disabled={loading}>
          {loading ? 'Loading ...' : 'Submit'}

        </Button>
      </div>
      {isError && <div className="text-xs text-red-500">All fields are required!</div>}
      {isSuccess && <div className="text-xs text-red-500">Successfully sent!</div>}
      </div></div>
    </div>
  );
};

export default AddScientificCorner;
