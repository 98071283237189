import React, { useEffect, useState } from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';

import { ReactComponent as Arrow } from '../images/svg/arrow.svg';
import { ReactComponent as Doctor } from '../images/svg/doctor.svg';
import { Dialog, DialogContent, DialogFooter } from '../components/ui/dialog';
import { collection, getDocs,  } from 'firebase/firestore';

import { Button } from '../components/ui/button';
import { Link } from 'react-router-dom';
import Badge from '../components/inputs/Badge';

import {  useNavigate } from 'react-router-dom';


import { ArrowRight } from 'lucide-react';
import { db } from '../utils/firebase';

export default function ScientificCorner() {
const navigate = useNavigate();

const [services, setServices] = useState(null);
const [filteredServices, setFilteredServices] = useState(null);
const [filter, setFilter] = useState('All');

const [openModal, setOpenModal] = useState(false);
const [selectedItem, setSelectedItem] = useState(null);


  const getData = async () => {
    try {
      
    const announcementsRef = collection(db, 'scientific_corner');
    const querySnapshot = await getDocs(announcementsRef);

    const serviceList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })).sort((a, b) => new Date(b.date) - new Date(a.date));
    
    setServices(serviceList);
    setFilteredServices(serviceList);

  } catch (error) {
    console.log('error',error);
  }
  };

  useEffect(() => {
    getData()
  }, [])


 
  
  

  const filterData = (type) => {
    setFilter(type);
    setFilteredServices(services.filter(data=>type !=='All' ?data.type ===type :true))
  }

  // const copyLink=()=>{
  //   navigator.clipboard.writeText(`${process.env.REACT_APP_URL}scientific-corner`);
  //   toastSucess('Copied to clipboard!');
  // }
  const handleOpenModal = (service) => {
    // setSelectedItem(service)
    // setOpenModal(true)
    navigate(`/scientific-corner/${service.id}`) 
    }

  const filtersButton = ['All', 'Physiotherapy', 'Nutrition', 'Family Medicine', 'Women’s Health','Body Image','Feminine Adventure','Emotional Dependance','Chronic Pain', 'Other'];

  return (
    <>
      <MenuBar />
      <div
        className="bg-scientificCorner mt-px sm:h-[448px] h-[248px]"
        style={{ backgroundSize: '100% 100%' }}
      >
        <div className="px-10 sm:px-0 max-w-7xl mx-auto w-full h-full flex items-center">
          <div>
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">Our Blog</div>
            </div>
            <div className="D1 font-bold text-bold_secondary">Scientific Corner</div>
            <p className="H4 max-w-sm pt-4">
              Get to know more about multiple health topics through the multi-disciplinary approach
              of all our team members.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          boxShadow: '1px 2px 4px 1px #0000000D'
        }}
        className="  h-16 sm:h-32 w-full  overflow-y-scroll "
      >
        <div className='flex h-full justify-between items-center max-w-7xl sm:px-0 px-10 mx-auto'>
        <div className="flex sm:flex-wrap justify-start items-center ">
          {filtersButton.map((btn) =>  (
            <div className='m-2'   key={btn}>
            <Button onClick={()=>filterData(btn)}
            className={`h-7  ${filter === btn?'bg-bold_secondary  text-white':'bg-white'}`}
            variant="outline">
              {btn}
            </Button>
            </div>
          ))}
        </div>
        <div className="B2 text-color_909090 hidden sm:block">Showing {filteredServices?.filter(data=>filter !=='All' ?data.type ===filter :true)?.length?? 0} articles</div>
      </div>
      </div>
      <div className="max-w-7xl mx-auto px-10 sm:px-0  py-14">
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 ">
          {filteredServices?.length===0?<div className='w-full sm:col-span-3 text-bold_secondary font-bold text-2xl col-span-1 text-center'>No Data</div>: filteredServices?.map((service, index) => (
            <div
              style={{ boxShadow: '0px 1px 7px 1px #00000026' }}
              className=" rounded-2xl mx-auto w-full cursor-pointer"
              key={index}
              onClick={()=>handleOpenModal(service)}
            >
              <div className='h-[281px] overflow-hidden'>
              <img src={`https://firebasestorage.googleapis.com/v0/b/motion-emontion.appspot.com/o/${service.url}?alt=media`} className="w-full h-full" />
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center  w-full">
                  <div className="B2 text-color_909090">{service.date}</div>
                  <div className='cursor-pointer' onClick={()=>handleOpenModal(service)}>
                    <Arrow className="w-8 h-8" />
                  </div>
                </div>
                <div className="H3 pt-4 font-bold text-color_414141">{service.title}</div>
                <div className="B2 pt-4 text-color_909090">{service.doctor}</div>
                <div className="pt-4">
                  <Badge type={service.type} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="grid sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white">
        <div className="sm:ml-auto order-2 sm:order-1 pt-10 sm:pt-0">
          <Doctor className="relative sm:left-5 px-8 sm:px-0 w-5/6 m-auto sm:m-0 sm:w-auto" />
        </div>
        <div className="sm:h-[54%] order-1 sm:order-2 sm:w-2/3 mt-auto mb-7 bg-primary py-16 pt-11 flex flex-col justify-center items-center">
          <div className="H3 text-center max-w-xs font-semibold mt-5">
          &rsquo;A doctor&rsquo;s object should be to find health. Anyone can find disease.&rsquo;
          <br/>
          (Andrew Taylor Still)
          </div>
          <div className="H3 font-semibold mt-6">Contact our experts</div>
          <Link to={'/contact-us'} className="border-white mt-6 hover:text-primary hover:bg-white border flex items-center justify-center px-4 py-2 rounded-lg B1 font-semibold ">
            Contact us
            <ArrowRight className="ml-1 w-4 h-5" />
          </Link>
        </div>
      </div>
      <Dialog open={openModal} onOpenChange={() => setOpenModal(prev => !prev)}>
        <DialogContent className="max-w-3xl p-0 overflow-hidden border-black ">
          <div className="overflow-y-auto max-h-[calc(100vh-15rem)]">
            <div className='overflow-hidden h-72'>
            <img src={`https://firebasestorage.googleapis.com/v0/b/motion-emontion.appspot.com/o/${selectedItem?.url}?alt=media`} className="w-full" />
            </div>
            <div className="w-4/5 mx-auto mb-6 mt-8">
              <div className="H3 font-bold text-bold_secondary">{selectedItem?.title ?? ''}</div>
              <div className="B2 text-color_909090 mt-2">{selectedItem?.doctor}</div>
              <div className='mt-6 B1' dangerouslySetInnerHTML={{ __html: selectedItem?.description.replace(/\n/g, '<br>') }} ></div>
            </div>
          </div>
          <DialogFooter className="w-4/5 sm:justify-start  mx-auto pb-10">
            <Link
              to="/contact-us"
              className={`B1 bg-bold_secondary text-center sm:text-left  hover:text-black  px-5 py-3 rounded-md text-white`}
            >
              Contact us
            </Link>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Footer />
    </>
  );
}
