import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../components/navigation/Sidebar';
import Announcement from '../components/pages/Announcement';
import AddAnnouncement from '../components/pages/AddAnnouncement';
import { Navigate,  } from 'react-router-dom';
import ScientificCorner from '../components/pages/ScientificCorner';
import AddScientificCorner from '../components/pages/AddScientificCorner';


function Cms() {

  const generateRandomKey = () => Math.random().toString(36).substring(7); // Generate a random key

  return (
    <div>
      <div className={`w-full flex`}>
        <Sidebar
        />
        <Routes>
          <Route
            element={<Announcement  key={generateRandomKey()} />}
            path="/announcement"
          />
          <Route
            element={<AddAnnouncement  />}
            path="/announcement/add"
          />
           <Route
            element={<ScientificCorner  key={generateRandomKey()} />}
            path="/scientific-corner"
          />
          <Route
            element={<AddScientificCorner  />}
            path="/scientific-corner/add"
          />
          <Route path="*" element={<Navigate to="/admin/announcement" />} />
        </Routes>
      </div>
    </div>
  );
}

export default Cms;
