import React, { useEffect, useState } from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { collection, getDocs,  } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../utils/firebase';
import { ReactComponent as LeftGreen } from '../images/svg/leftGreen.svg';
import { ReactComponent as RightBlue } from '../images/svg/rightBlue.svg';

export default function ScientificCornerDetails() {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      
    const announcementsRef = collection(db, 'scientific_corner');
    const querySnapshot = await getDocs(announcementsRef);

    const serviceList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })).filter((service) => service.id === id);
    
    console.log('serviceList[0]',serviceList[0]);
    
   setSelectedItem(serviceList[0])

  } catch (error) {
    console.log('error',error);
  }
  };

  useEffect(() => {
    getData()
  }, [])
  
 
 

  return (
    <>
      <MenuBar />
      <div className=' relative'>
      <div className='absolute left-0 top-44 hidden sm:block'><RightBlue/></div>
      <div className='absolute right-0 bottom-44 hidden sm:block'><LeftGreen/></div>
      
      
      {selectedItem?.url?<div className='max-w-2xl mx-auto mt-14 mb-16 relative px-10 sm:px-0'>
        
        

        <img src={`https://firebasestorage.googleapis.com/v0/b/motion-emontion.appspot.com/o/${selectedItem.url}?alt=media`} />
        <div className='text-bold_secondary font-bold  text-2xl sm:text-4xl mt-16 mb-5 text-center'>{selectedItem.title.trim()}</div>
        <div className='text-color_909090 font-normal text-base sm:text-xl text-center'>{selectedItem.doctor}</div>
        <div className='sm:py-16 py-8' dangerouslySetInnerHTML={{ __html: selectedItem?.description.replace(/\n/g, '<br>') }}></div>
        <div className="py-3">
        <Link
              to="/contact-us"
              className={`B1 bg-bold_secondary  hover:text-black  px-5 py-3 rounded-md text-white ${
                 '' 
              }`}
            >
              Contact us
            </Link>
          </div>

      </div>:<div className='w-full text-center h-screen text-color_414141 text-base font-normal'><div className='py-16'>Loading...</div></div>}
      </div><Footer />
    </>
  );
}
