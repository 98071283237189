import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';
import { ReactComponent as Approch } from '../images/svg/approch.svg';
import { ReactComponent as Message } from '../images/svg/message.svg';
import { ReactComponent as SmallGreen } from '../images/svg/smallGreen.svg';
import { ReactComponent as Green } from '../images/svg/green.svg';
import { ReactComponent as LinkedIn } from '../images/svg/linkedIn.svg';
import AboutUsImg from './../images/new/aboutUs.png';
import AboutUsImg3 from './../images/new/aboutUs-3.png';
import Team from './../images/new/team.png';
import Slider from "react-slick";

import { ReactComponent as Empathy } from '../images/svg/empathy.svg';
import { ReactComponent as Collaboration } from '../images/svg/collabotation.svg';
import { ReactComponent as Personalization } from '../images/svg/personalization.svg';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  const settings = {
    dots: false,
    touchMove:false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

  };
  let sliderRef = React.useRef(null);


  const slider = [
    {
      title: 'Empathy',
      logo: Empathy,
      description:
        'We approach each patient with empathy, understanding their unique needs and challenges.'
    },
    {
      title: 'Collaboration',
      logo: Collaboration,
      description:
        'working together, we provide a comprehensive and unified approach to healthcare, optimizing outcomes for our patients.'
    },
    {
      title: 'Personalization',
      logo: Personalization,
      description:
        'Personalized care ensures that our patients receive the right interventions at the right time, enhancing the overall effectiveness of their treatment.'
    }
  ];

const teamData=[{
  name: 'Toni Merhi',
  title: 'Co-Founder',
  img:Team,
  description:'Physiotherapist, Lebanese University, faculty of public health graduate, 2011\n\n International Osteopathy Training intern, post graduate in osteopathic manipulative techniques\n\n Certified provider in Logotherapy\n\n Practical biodecoding counselor\n\n',
  href:"https://www.linkedin.com/in/toni-merhi-44a259123"
},{
  name: 'Dr. Maya Naddaf',
  title: 'Co-Founder',
  img:Team,
  description:'General practitioner, Saint Joseph University Graduate, 2008\n\n Practical biodecoding coach\n\n Systemic approach coach\n\n Emotional Disorders Transdiagnostic Treatment\n\n',
  href:"https://www.linkedin.com/in/maya-naddaf-1a3a03282"
},{
  name: 'Ghady Nader',
  title: '',
  img:Team,
  description:'Physiotherapist, Lebanese German University graduate, 2021\n\n International Osteopathy Training intern\n\n  Dry Needling\n\n Dry Cupping\n\n Taping and Stapping\n\n ',
  href:"https://www.linkedin.com/in/ghady-nader-40b8b9222"
},{
  name: 'Dr. Lama Abi Khalil El Khoury',
  title: '',
  img:Team,
  description:'Family physician, Saint Joseph University Graduate, 2015\n\n Gerontology counseling\n\n Sexology & Breastfeeding counseling and education\n\n  Creighton model fertility care practitioner intern\n\n',
  href:"https://www.linkedin.com/in/dr-lama-abikhalil"
},{
  name: 'Dr. Doumit Chahwan',
  title: '',
  img:Team,
  description:'Internal medicine physician, Saint Joseph University Graduate, 2014\n\n Pain management specialist at Centre d\'Evaluation et de Traitement de la Douleur, Hôpital Lariboisière, Paris\n\n',
  href:""
},{
  name: 'Dr. Cedra Ghsoub Estephan',
  title: '',
  img:Team,
  description:'Family physician, Saint Joseph University Graduate, 2014\n\n Master of Science in Human Nutrition',
  href:"https://www.linkedin.com/in/dr-cedra-ghsoub-4a92421b3"
}]

  const next = () => {
    sliderRef.slickNext();
  };
  const prev = () => {
    sliderRef.slickPrev();
  };

 

  return (
    <>
      <MenuBar />
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:py-20 py-10 max-w-7xl mx-auto sm:px-0 px-10">
        <img src={AboutUsImg} />
        <div className="  flex items-center justify-center">
          <div className="justify-center items-center sm:pt-0 pt-10">
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">GET TO KNOW US</div>
            </div>
            <div className="D1 font-bold text-bold_secondary pb-6">About us</div>
            <div>
              Motion Emotion was launched in January 2021 by Toni Merhi and Dr. Maya Naddaf in order
              to provide a healthcare entity centered on the person and aiming to take care of the
              patient’s global wellbeing whether on the physical, emotional, social, or spiritual
              level.
              <br/><br/>
              Today, we are a team of healthcare professionals collaborating together to
              provide you with the ultimate holistic journey toward global healing & wellbeing.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-aboutUs-2  mt-px sm:px-0 px-10 sm:h-[768px] h-[940px] sm:bg-cover bg-right ">
        <div className="max-w-7xl h-full mx-auto">
          <div className="h-full sm:w-1/2   ml-auto  flex items-center justify-center ">
            <div>
              <div className=" text-2xl sm:text-[48px] sm:leading-[57.6px]  font-bold text-bold_secondary pb-4">
                Your wellness journey starts here!
              </div>
              <div className="B1">
                Whether you are seeking relief from physical pain, support for mental health
                concerns, or comprehensive medical care, Motion Emotion is your partner on the path
                to wellness. We invite you to explore the possibilities of a healthier, happier life
                with us. We look forward to accompanying you on your journey to optimal well-being.
              </div>
              <div className="flex pt-10">
                <div className="pr-6">
                  <Message />
                </div>
                <div>
                  <div className="H2 font-bold text-bold_secondary pb-2">Our Story</div>
                  <div className="B1">
                    Established with a vision to redefine healthcare, Motion Emotion was founded on
                    the principle that true well-being encompasses physical, mental, and medical
                    aspects. We saw a need for a healthcare destination where individuals could find
                    a seamless blend of expertise, compassion, & innovation.
                  </div>
                </div>
              </div>
              <hr className="my-8 border-bold_secondary" />
              <div className="flex">
                <div className="pr-6">
                  <Approch />
                </div>
                <div>
                  <div className="H2 font-bold text-bold_secondary pb-2">Our Approach</div>
                  <div className="B1">
                    What sets us apart is our commitment to a holistic approach to treatment. We go
                    beyond symptoms, aiming to understand the root causes of your health challenges.
                    By combining the latest advancements in physiotherapy, psychotherapy, and
                    medical care, we design personalized treatment plans that prioritize your
                    holistic well-being.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative'>
      <SmallGreen  className='absolute right-0 z-[-10]'/>
      </div>
      <div className="mx-auto  max-w-7xl py-10 sm:py-28 px-10 sm:px-0 ">
        <div className="grid  grid-cols-1  sm:grid-cols-3">
          <div className="sm:pt-6 sm:border-y-0 border-bold_secondary sm:border sm:my-6 mt-6 sm:border-l-0 sm:pr-8">
            <div className="H2 font-bold text-bold_secondary pb-2">Our Mission</div>
            <div className="B1">
              At Motion Emotion, our mission is to cultivate a healthier and happier community by
              delivering comprehensive and compassionate healthcare. We are dedicated to providing a
              holistic approach to treatment, integrating the expertise of diverse healthcare
              professionals. Our commitment is to empower individuals on their journey to optimal
              well-being through personalized and patient-centric care.
            </div>
          </div>
          <div className="sm:pt-6 sm:pl-8 my-6">
            <div className="H2 font-bold text-bold_secondary pb-2">Our Vision</div>
            <div className="B1">
              At Motion Emotion, we envision a future where every individual can access a seamless
              continuum of care, where physical, mental, and medical aspects are addressed
              collaboratively. Our aspiration is to create a community that thrives on holistic
              health, embracing life to the fullest potential.
            </div>
          </div>
          <img src={AboutUsImg3} className="sm:pl-8" />
        </div>
      </div>

     

      <div className="max-w-7xl sm:flex hidden mx-auto h-[550px]">
      <div className="text-bold_secondary D1 relative flex-shrink-0 font-bold w-[295px] ">
        <div>Our Values</div>  
        <div className='mt-44'>
        {/* <button onClick={prev} className='rounded-full border border-bold_secondary mx-1' >
<ArrowLeft/>
                    </button>  
        <button onClick={next} className='rounded-full border border-bold_secondary mx-1' >
                        <ArrowRight/>
                    </button> */}
                    </div>     
          </div>
        
          <div>
        <div
          style={{
            background: 'linear-gradient(90deg, #A0CFCB 0%, #0E6C63 100%)'
          }}
          className="absolute rounded-2xl flex w-full h-1/4"
        >
          <div className=' max-w-5xl w-full '>


          <div className="slider-container mt-16 ">
      <Slider ref={slider => {
          sliderRef = slider;
        }} {...settings}>
      {slider.map((record, index) => (
        <div className='my-1' key={index} >
                <div
                  style={{
                    boxShadow: '0px 4px 4px 0px #00000026'
                  }}
                  className="flex px-8 justify-center items-center text-center flex-col w-[295px] mx-auto  h-[374px] rounded-lg  bg-white"
                  
                >
                  <record.logo />
                  <div className='mb-4 mt-10 '> {record.title}</div>
                  <div> {record.description}</div>
                </div>
                </div>
            ))}
            
      </Slider>
    </div>
      
        </div></div>
        </div>
      </div>
      <div className='relative'>
      <SmallGreen  className='absolute bottom-[10%] right-0 z-[-10]'/>
      <Green  className='absolute sm:top-10 top-64  z-[-10]'/>
      <div className="mx-auto max-w-5xl px-10 sm:px-0">
        <div className="text-center pb-4 D1 font-bold text-bold_secondary">Our Team</div>
        <div className="text-center B1 pb-20">
          Our team is a diverse and dedicated group of professionals, including highly skilled
          physiotherapists, empathetic psychotherapists, and experienced medical doctors. Together,
          we collaborate seamlessly to address your unique health needs, recognizing that your
          journey to wellness is multifaceted.
        </div>
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-8 pb-36">
          {teamData.map((record,index)=>(  <div key={index} className="border border-bold_secondary rounded-xl test-container ">

          <div className="original-div">
          <div className=" w-full h-96  overflow-hidden bg-bold_secondary text-white rounded-xl p-6">
<div className='H3 font-bold min-h-[45px]'>{record.name}</div>
<div className='B1 font-semibold'>{record.title}</div>
<p className='B2 my-6' dangerouslySetInnerHTML={{ __html: record.description.replace(/\n/g, '<br>') }}></p>
<div className=' h-full'>
  <div className='absolute bottom-4 w-full'>
<hr className='w-1/2 mb-6 bg-white ' />
<Link className='bg-red-400' to={record.href}><LinkedIn /></Link>
</div>
</div>
  </div>
            {/* <img className="w-full" src={record.img} /> */}
            </div>
            {/* <div className="flipped-div w-full bg-bold_secondary text-white rounded-xl p-6">
<div className='H3 font-bold'>{record.name}</div>
<div className='B1 font-semibold'>{record.title}</div>
<p className='B2 my-6' dangerouslySetInnerHTML={{ __html: record.description.replace(/\n/g, '<br>') }}></p>
<hr className='w-1/2 mb-6 bg-white ' />
<Link className='bg-red-400' to={record.href}><LinkedIn /></Link>
  </div> */}
          </div>))}
        
         
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
