import React from 'react';

// eslint-disable-next-line react/prop-types
const Badge = ({ type }) => {
  let badgeClass = '';
  let dotColor = '';

  switch (type) {
    case 'Physiotherapy':
      badgeClass = 'text-[#676767] bg-[#ECECEC]';
      dotColor='bg-[#909090]';
      break;
    case 'Nutrition':
      badgeClass = 'text-[#1C3666] bg-[#EAEFF7]';
      dotColor='bg-[#2F5AAA]';
      break;
    case 'Family Medicine':
      badgeClass = 'text-[#0B514A] bg-[#E7F3F2]';
      dotColor='bg-[#12877C]';
      break;
      case 'Women’s Health':
      badgeClass = 'text-[#2F9461] bg-[#F6FEF9]';
      dotColor='bg-[#6AD09D]';
      break;
      case 'Mental Health':
      badgeClass = 'text-[#A54100] bg-[#FEEDE1]';
      dotColor='bg-[#F86200]';
      break;
      case 'Other':
      badgeClass = 'text-[#CCAA44] bg-[#FFFAEE]';
      dotColor='bg-[#FFD556]';
      break;
    default:
      badgeClass = 'text-[#FF6666] bg-[#FFEEEE]';
      dotColor='bg-red-500';
  }

return (
    <div style={{ borderRadius: '150px' }} className={`inline-flex  font-medium text-sm ${badgeClass} px-2`}>
        <div className={`h-[7px] w-[7px] rounded-full self-center ${dotColor}`}></div>
        <div className='px-1'>{type}</div>
    </div>
);
};

 

export default Badge;
