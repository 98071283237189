import toast from 'react-hot-toast';

const toastSucess = text =>
  toast.success(text, {
    duration: 4000,
    position: 'top-right',
    iconTheme: {
      primary: '#12877C',
      secondary: '#fff'
    }
  });

const toastError = text =>
  toast.error(text, {
    duration: 4000,
    position: 'top-right',
    iconTheme: {
      primary: '#12877C',
      secondary: '#fff'
    }
  });

const toastPromise = (fetch, success, fail) =>
  toast.promise(
    fetch,
    {
      loading: 'Loading...',
      success: data => success,
      error: err => fail
    },
    {
      duration: 4000,
      position: 'top-right',
      iconTheme: {
        primary: '#12877C',
        secondary: '#fff'
      }
    }
  );

const toastLoading = sentence =>
  toast.loading(sentence ? sentence : 'Loading', {
    position: 'top-right',
    iconTheme: {
      primary: '#12877C',
      secondary: '#fff'
    }
  });

export { toastSucess, toastError, toastPromise, toastLoading };
