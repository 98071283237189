import React, { useEffect, useState } from 'react';

import { Button } from './../components/ui/button';
import Input from './inputs/Input';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../utils/firebase';

import { signInWithEmailAndPassword } from 'firebase/auth';
import {  useNavigate } from 'react-router-dom';
import { toastLoading, toastError } from '../utils/toaster';
import toast from 'react-hot-toast';

 

export default function Login() {
  const [userInfo, setuserInfo] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const handleChange = e => {
    setuserInfo(prev => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/admin/announcement'); 
      } else {
        // User is signed out
        
      }
    });
  }, []);

  const logIn = userInfo => {
    if (userInfo.email == '' || userInfo.password == '') {
      toastError('All fields are required!');
      return;
    }
    let toastId = toastLoading();
    
    signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
      .then(userCredential => {
        // Signed in
        toast.dismiss(toastId);
        navigate('/admin/announcement');
      })
      .catch(error => {
        var errorCode = error.code;
        console.log(errorCode);
        let textToast = 'Something went Wrong!';
        if (errorCode == 'auth/wrong-password' || errorCode == 'auth/user-not-found') {
          textToast = 'Wrong email or password!';
        } else if (errorCode == 'auth/invalid-email') {
          textToast = 'Invalid email!';
        }
        toast.error(textToast, {
          id: toastId
        });
      });
  };

  return (
    <div className="h-screen  bg-gray-200">
      <div className="w-full h-full ">
        <div className="auth-screen-height flex items-center justify-center ">
          <div className=" shadow-lg rounded-lg p-8 bg-white max-w-sm w-full">
            <div className="text-black font-bold text-3xl text-center pb-6">Sign in</div>
            <div className='pb-6 w-full text-center'>Motion.Emotion</div>
            <div className="pb-2">
              <Input onChange={handleChange} name={'email'} placeholder="Email" />
            </div>
            <div className="pb-2">
              <Input
                onChange={handleChange}
                type={'password'}
                name={'password'}
                placeholder="Password"
              />
            </div>
            <div className="py-6 flex  justify-center">
              <Button onClick={() => logIn(userInfo)} >
                SIGN IN
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
