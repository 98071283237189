import React from 'react';
import Logo from '../images/svg/logo.svg';

import { ReactComponent as Instagram } from '../images/svg/instagram.svg';
import { ReactComponent as Facebook } from '../images/svg/facebook.svg';
import { ReactComponent as Tweeter } from '../images/svg/tweeter.svg';
import { ReactComponent as Youtube } from '../images/svg/youtube.svg';

import { ReactComponent as Email } from '../images/svg/email.svg';
import { ReactComponent as Phone } from '../images/svg/phone.svg';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    
    <div className="bg-primary ">
      <div className='mx-auto px-10 sm:px-0 max-w-7xl grid grid-cols-2 md:grid-cols-4  text-white py-8'>
      <div className="mx-auto  pb-10 sm:pb-0 col-span-2 sm:col-span-1">
        <div>
          <img src={Logo} className='mx-auto' />
        </div>
        <div className="flex flex-row space-x-4 pt-6">
          <Link to="#">
            <Facebook className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Tweeter className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Instagram className="hover:fill-secondary" />
          </Link>
          <Link to="#">
            <Youtube className="hover:fill-secondary" />
          </Link>
        </div>
      </div>
      <div className="mx-auto ">
        <div className="pb-4">
          <div className="H3 font-semibold">Our Services</div>{' '}
          <div className="w-16 bg-secondary h-[2px] mt-2"></div>
        </div>
        <p className="B1">
        Physiotherapy
          <br />
          General Osteopathy
          <br />
          Logotherapy
          <br />
          Biodecoding          <br />
          Home Visits <br />
          Emotional Disorders Treatment      
          
        </p>
      </div>
      <div className="mx-auto sm:m-0">
        <div className="pb-4">
          <div className="H3 font-semibold">&nbsp;</div>
          <div className="w-16 bg-transparent h-[2px] mt-2"></div>
        </div>
        <div  className='B1'>
          Systemic Coaching
        <br/>
        Pain Management
        <br/>
        Family Medicine     
        <br/>
        Nutrition
        <br/>
        
        Women&apos;s Health <br/>
        Breastfeeding Counseling
      
        </div>
      </div>
      <div className="mx-auto pt-10 sm:pt-0">
        <div className="pb-4">
          <div className="H3 font-semibold">Get in Touch</div>
          <div className="w-16 bg-secondary h-[2px] mt-2"></div>
        </div>
        <p className="B1">
          Ground Floor, Mirza
          <br />
          building, facing KMC
          <br />
          hospital, Ghazir,
          <br />
          Kfarehbab bifurcation
        </p>
        <div className=" py-4 B2 hidden sm:flex">
          <Email className="mr-4" />
          info@motionemotion.clinic
        </div>
        <div className="  B2 hidden sm:flex">
          <Phone className="mr-4" />
          +961 70 216 356
        </div>
      </div>
      <div className='mx-auto block pt-10 sm:pt-0 sm:hidden'>
      <div className=" py-4 B2 flex">
          <Email className="mr-4" />
          <div className='text-xs'>
          info@motionemotion<br></br>.clinic
          </div>
        </div>
        <div className="  B2 flex ">
          <Phone className="mr-4" />
          <div className='text-xs'>
          +961 70 216 356
          </div>
        </div>
      </div>
    </div></div>

  );
}
