import React from 'react';
import Services from './pages/Services';
import Cms from './layouts/Cms';

import  { Toaster } from 'react-hot-toast';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ScientificCorner from './pages/ScientificCorner';
import ScientificCornerDetails from './pages/ScientificCornerDetails';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import Announcement from './pages/Announcement';
import Main from './pages/Main';
import Login from './components/Login';
import ScrollToTopOnMount from './components/navigation/ScrollToTopOnMount';

function App() {
    return (
    <>
      <Router>
      <ScrollToTopOnMount />
        <Routes>
        <Route element={<Main />} path="/" />
        <Route element={<Services />} path="/services" />
        <Route element={<Announcement />} path="/announcements" />
        <Route element={<AboutUs />} path="/about-us" />
        <Route element={<ScientificCorner />} path="/scientific-corner" />
        <Route element={<ScientificCornerDetails />} path="/scientific-corner/:id" />  
        <Route element={<Contact />} path="/contact-us" />
        <Route element={<Login />} path="/login" />
          <Route element={<Cms />} path="/admin/*" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <Toaster />
      <link
  rel="stylesheet"
  type="text/css"

  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
/>
<link
  rel="stylesheet"
  type="text/css"
  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
/>
    </>
  );
}

export default App;
