import React, { useState } from 'react';

import { ReactComponent as ArrowDown } from '../images/svg/arrowDown.svg';

const Accordion = () => {
  const [isOpen, setIsOpen] = useState(-1);
  const questions = [
    { name: 'What makes Motion Emotion different from other healthcare providers?', reply: 'Motion Emotion stands out due to its holistic integration of physiotherapy, osteopathy, various therapeutic techniques, and medical care (see Our Services). Our collaborative approach, personalized treatment plans, and commitment to innovation create a unique healthcare experience that prioritizes your overall well-being.' },
    {
      name: 'How long is a typical appointment?',
      reply:
        'Appointment duration varies based on the nature of the service. Physiotherapy typically last up to 90 minutes, while medical consultations may vary. Our team will provide you with specific details when scheduling your appointment.'
    },
    { name: 'Is Motion Emotion suitable for all ages?', reply: 'Absolutely. We cater to individuals of all ages, from children to seniors. Our diverse team is equipped to address the unique health needs of each age group, ensuring that everyone receives tailored and age-appropriate care, especially for physiotherapy and medical services. Coaching services are mainly addressed to adults; however, children cases can be discussed on case-by-case basis with the related coach.' },
    { name: "How can I stay updated on Motion Emotion's events and health tips?", reply: `Stay connected with us through our scientific corner section and news section. We regularly share valuable health tips, upcoming events, and updates to keep you informed and engaged with our wellness community.` }
  ];

  const toggleAccordion = index => {
    setIsOpen(prev => (index == prev ? -1 : index));
  };

  return (
    <div className="w-full">
      <div>
        {questions.map((question, index) => (
          <div key={index} className="mb-6 bg-white border rounded-lg p-6  flex flex-col  ">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <div className="font-bold H4 text-bold_secondary">{question.name}</div>
              <div>
                <ArrowDown className={`transition-all ${isOpen === index ? 'rotate-180' : ''}`} />
              </div>
            </div>
            {isOpen == index && <div className="pt-3.5 B3">{question.reply}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
