import React, { useState } from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';
import { ReactComponent as Arrow } from '../images/svg/arrow.svg';

import { ReactComponent as Green } from '../images/svg/green.svg';
import { ReactComponent as SmallGreen } from '../images/svg/smallGreen.svg';

import { Link } from 'react-router-dom';

import { Dialog, DialogContent, DialogFooter } from '../components/ui/dialog';

import S1 from '../images/new/s1.png';
import S2 from '../images/new/s2.png';
import S3 from '../images/new/s3.png';
import S4 from '../images/new/s4.png';
import S5 from '../images/new/s5.png';
import S6 from '../images/new/s6.png';
import S7 from '../images/new/s7.png';
import S8 from '../images/new/s8.png';
import S9 from '../images/new/s9.png';
import S10 from '../images/new/s10.png';
import S11 from '../images/new/s11.png';
import S12 from '../images/new/s12.png';
import S13 from '../images/new/s13.png';
import S14 from '../images/new/s14.png';

export default function Services() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const servicesArray = [
    { description:`<b>“The art of healing through physical means”</b>\n\n including all ages rehabilitation for several kinds of medical conditions, sports injuries, traumatology, post-operative rehab, acute and chronic pain`,writer:'by Toni Merhi & Ghady Nader',onlineConsultation: false, name: 'Physiotherapy', img: S1 },
    { description:'General osteopathic treatment, Mitchell/MET technique, recoil technique, strain counterstrain/ Jones technique and other techniques',writer:'by Toni Merhi & Ghady Nader',onlineConsultation: false, name: 'General Osteopathy', img: S2 },
    { description:'An empirically based Meaning Centred Approach developed by worldwide known neuropsychiatrist and neurologist Dr. Viktor Frankl',writer:'by Toni Merhi',onlineConsultation: false, name: 'Logotherapy', img: S3 },
    { description:'A new approach to therapy and health based on the biological meaning of symptoms through decoding the underlying emotional cause.',writer:'by Dr. Maya Naddaf & Toni Merhi (onsite and online)',onlineConsultation: false, name: 'Practical Biodecoding', img: S4 },
    { description:'A global approach to various topics related journeys of individuals within their environment: Abundance programs, Family & workplace dynamics, Death & loss grieving, Feminine adventure, Traumatic memories & limiting beliefs healing, Conflictual relationships patterns.',writer:'by Dr. Maya Naddaf (onsite and online)',onlineConsultation: false, name: 'Systemic Coaching for Individuals', img: S5 },
    { description:`A new approach to problematic emotional processing, specifically aversive reactions to emotions that lead to avoidant coping strategies:
    Setting Goals and Maintaining Motivation, Understanding Emotions, Mindful Emotion Awareness, Cognitive Flexibility, Countering Emotional Behaviours, Understanding and Confronting Physical Sensations, Emotion Exposures`,writer:'by Dr. Maya Naddaf (onsite and online)',onlineConsultation: false, name: 'Emotional Disorders Treatment', img: S6 },
    { description:'Personalised guidance to promote wellness and healthy eating habits particularly in certain diseases',writer:'by Dr. Cedra Ghsoub Estephan',onlineConsultation: false, name: 'Nutrition Counselling', img: S7 },
    { description:'Online consultations and occasional non-urgent onsite consultations for chronic pain management counselling',writer:'by Dr. Doumit Chahwan',onlineConsultation: false, name: 'Chronic Pain Counselling', img: S8 },
    { description:'for main acute and chronic diseases for children, adults, and elderly patients, preventive medicine, patient education, and health promotion',writer:'by Dr. Lama Abi Khalil El Khoury',onlineConsultation: false, name: 'Family Medicine Consultations', img: S9 },
    { description:'-by Dr. Lama Abi Khalil El Khoury: mainly for elderly & bedridden patients and for breastfeeding moms\n\n-by Toni Merhi & Ghady Nader : home physiotherapy mainly for bedridden patients and severe cases',writer:'By Tony Merhi, Ghady Nader, and Dr. Lama Abi Khalil El Khoury',onlineConsultation: false, name: 'Home Visits', img: S10 },
    { description:'Sexology and fertility guidance\n\nDiscovery of the “inner soul” of human sexuality and marital bonding\n\nAssistance in achieving or avoiding pregnancy by natural methods',writer:'by Dr. Lama Abi Khalil El Khoury',onlineConsultation: false, name: 'Women’s Health', img: S11 },
    { description:'',writer:'by Dr. Lama Abi Khalil El Khoury',onlineConsultation: false, name: 'Breastfeeding Counselling', img: S12 },
    { description:'Your relationship with your body (overweight, menstrual issues, fertility…)\n\nFeminine energy & relationship with your emotions\n\nLove life & emotional dependence\n\nSelf-value and needs self-fulfilment\n\nDating and relationships guidance\n\nGlobal self-actualization',writer:' by Dr. Maya Naddaf',onlineConsultation: false, name: 'Group Workshops for Women', img: S13 },
    { description:'<b>-FABRY’S INTERNATIONAL VALUES PROGRAM:</b>\nprovides awareness of the importance unique values which constitute the identity of each person, their hierarchy, and ways to put them in action.\n\n<b>-THE MEANING AND PURPOSE DISCOVERY PROGRAM:</b>\nhelps providing an expansion of awareness towards identifying a robust personal unique value system serving a personal meaning and purpose.\n\n<b>-PURPOSE IN LIFE TEST:</b>\ntackles interrelated and connected aspects of life goals, respondents moods, and life meaning.',writer:'by Toni Merhi & Dr. Maya Naddaf',onlineConsultation: false, name: 'Logotherapy Tests', img: S14 }
  ];
  const handleOpenModal = serviceData => {
    setOpenModal(true);
    setSelectedItem(serviceData);
  };

  return (
    <>
      <MenuBar />
      <div
        className="bg-services sm:mt-px sm:h-[448px] h-[248px] "
        style={{ backgroundSize: '100% 100%' }}
      >
        <div className=" mx-auto max-w-7xl  px-10 sm:px-0 w-full h-full flex items-center">
          <div>
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">HOW WE CAN HELP YOU</div>
            </div>
            <div className="D1 font-bold text-bold_secondary">Our Services</div>
          </div>
        </div>
      </div>
      <div className="relative">
        <Green className="absolute top-40 z-[-10]" />
        <SmallGreen className="absolute z-[-10] right-0 top-1/3" />
        <SmallGreen className="absolute z-[-10]  bottom-40 " />
        <div className="mx-auto max-w-7xl px-10 sm:px-0 sm:pb-24 sm:pt-28 py-10 ">
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-4 ">
            {servicesArray.map((service, index) => (
              <div
                style={{ boxShadow: '0px 1px 7px 1px #00000026' }}
                className=" rounded-2xl mx-auto w-full bg-white cursor-pointer"
                key={index}
                onClick={() => handleOpenModal(service)}
              >
                <div className="relative">
                  <div className='p-1'>
                  <div className='h-32 overflow-hidden   rounded-2xl '>
                    <img src={service.img} className="w-full " />
                  </div>
                  </div>
                  {service.onlineConsultation && (
                    <div className="text-[#2F9461] text-sm font-[500] bg-[#F6FEF9] py-[2px] px-2 rounded-xl absolute  bottom-2 left-2">
                      Online Consultation
                    </div>
                  )}
                </div>
                <div className="flex justify-between items-center px-4 py-6 w-full">
                  <div className="H4 font-bold text-bold_secondary">{service.name}</div>
                  <div>
                    <Arrow className="cursor-pointer" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Dialog open={openModal} onOpenChange={() => setOpenModal(prev => !prev)}>
        <DialogContent className="max-w-3xl p-0 overflow-hidden border-black ">
          <div className="overflow-y-auto max-h-[calc(100vh-15rem)]">
            <div className='overflow-hidden h-72'>
            <img src={selectedItem?.img ?? ''} className="w-full" />
            </div>
            <div className="w-4/5 mx-auto mb-6 mt-8">
              <div className="H3 font-bold text-bold_secondary">{selectedItem?.name ?? ''}</div>
              <div className="B2 text-color_909090 mt-2">{selectedItem?.writer}</div>
              <div className='mt-6 B1' dangerouslySetInnerHTML={{ __html: selectedItem?.description.replace(/\n/g, '<br>') }}></div>
            </div>
          </div>
          <DialogFooter className="w-4/5  sm:justify-start  mx-auto pb-10">
            <Link
              to="/contact-us"
              className={`B1 bg-bold_secondary text-center sm:text-left  hover:text-black  px-5 py-3 rounded-md text-white`}
            >
              Contact us
            </Link>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Footer />
    </>
  );
}
