import React, { useEffect, useState } from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';
import { ReactComponent as View } from '../images/svg/view.svg';
import { ReactComponent as Doctor } from '../images/svg/doctor.svg';
import { ReactComponent as Green } from '../images/svg/green.svg';

import { Button } from '../components/ui/button';
import { Link } from 'react-router-dom';

import { db } from '../utils/firebase';
import { collection, getDocs,  } from 'firebase/firestore';

import { ArrowRight } from 'lucide-react';


import Announcement1 from './../images/new/announcement1.jpg';
import Announcement2 from './../images/new/announcement2.jpg';

export default function Announcement() {
  
  const [filteredAnnouncements, setFilteredAnnouncements] = useState(null);
  const [announcements, setAnnouncements] = useState(null);
  const [filter, setFilter] = useState('All');
  
  useEffect(() => {
    getData()
  }, [])
  
  const renderDate = (dateString) => {
    const date = new Date(dateString);

  // Format the date using Intl.DateTimeFormat
  return  new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }).format(date);
  }


  const getData = async () => {
    try {
      
    const announcementsRef = collection(db, 'announcements');
    const querySnapshot = await getDocs(announcementsRef);

    const announcementsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setAnnouncements(announcementsList);
    setFilteredAnnouncements(announcementsList);

  } catch (error) {
    console.log('error',error);
  }
  };

  const filterData = (type) => {
    setFilter(type);
    setFilteredAnnouncements(announcements.filter(data=>type !=='All' ?data.type ===type :true))
  }

  const filtersButton=['All','Workshops & Events','Visiting Consultants','Special Schedules','Collaboration','Recruitment Vacancies'];
  return (
    <>
      <MenuBar />
      <div
        className="bg-announcements sm:mt-px sm:h-[448px] h-[248px]"
        style={{ backgroundSize: '100% 100%' }}
      >
        <div className="px-10 sm:px-0 max-w-7xl mx-auto w-full h-full flex items-center">
          <div>
            <div className="flex">
              <Bone />
              <div className="B1 font-semibold text-primary">WHATS NEW</div>
            </div>

            <div className=" sm:text-5xl text-4xl  font-bold text-bold_secondary">Announcements</div>
          
          </div>
        </div>
      </div>
      <div
        style={{
          boxShadow: '1px 2px 4px 1px #0000000D'
        }}
        className="  h-16 w-full  overflow-y-scroll "
      >
        <div className='flex h-full justify-between items-center max-w-7xl sm:px-0 px-10 mx-auto'>
        <div className=" flex justify-center items-center space-x-4  ">
        {filtersButton.map(btn=><Button key={btn} onClick={()=>filterData(btn)} className={`h-7 hover:bg-primary text-primary border-primary ${filter === btn?'bg-primary text-white':'bg-white'}`} variant="outline">
            {btn}
          </Button>)}
          
         
        </div>
        <div className="B2 text-color_909090 hidden sm:block">Showing {filteredAnnouncements?.filter(data=>filter !=='All' ?data.type ===filter :true).length?? 0} Results</div>
      </div>
      </div>
      <div className="max-w-7xl mx-auto px-10 sm:px-0  py-14">
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 ">

          {filteredAnnouncements=== null ? <div className='w-full sm:col-span-3 col-span-1 font-bold text-2xl text-primary text-center'>Loading ...</div>:(filteredAnnouncements.length ===0?<div className='w-full sm:col-span-3 text-primary font-bold text-2xl col-span-1 text-center'>No Data</div>:filteredAnnouncements.filter(data=>filter !=='All' ?data.type ===filter :true).map((announcement, index) => (
            <div
              style={{ boxShadow: '0px 1px 7px 1px #00000026' }}
              className=" rounded-2xl mx-auto w-full"
              key={index}
            >
              <div className='overflow-hidden h-[270px]'>
                <img src={announcement.img==='Announcement1'?Announcement1:Announcement2} className="w-full" />
              </div>
              <div className="p-6   ">
                <div className="H3 text-center font-bold h-12  line-clamp-2 text-color_414141">{announcement.title}</div>
                <div className="B1  font-semibold  text-center mt-4 text-color_909090">{announcement.doctor}</div>
                <div className="mt-4  text-bold_secondary B2 text-center">
                 {renderDate(announcement.date)}
                </div>
              </div>
            </div>
          )))}
        </div>
      </div>
 <div className='relative'>
  <Green  className='z-[-10] absolute right-0  transform scale-x-[-1] sm:bottom-3/4 bottom-1/2'/>
      <div className="grid sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white">
        <div className="sm:ml-auto order-2 sm:order-1 pt-10 sm:pt-0">

          <Doctor className="relative sm:left-5 px-8 sm:px-0 w-5/6 m-auto sm:m-0 sm:w-auto" />
        </div>
        <div className="sm:h-[54%] order-1 sm:order-2 sm:w-2/3 mt-auto mb-7 bg-primary py-16 pt-11 flex flex-col justify-center items-center">
          <div className="H3 text-center max-w-xs font-semibold mt-5">
          &lsquo;Everything can be taken from a man but the freedom to choose one&apos;s attitude in any given set of circumstances.&lsquo;
          <br />
          (Dr. Viktor Frankl, Father of Logotherapy)
          </div>
          {/* <div className="H3 font-semibold mt-6">Contact our experts</div> */}
          <Link to={'/contact-us'} className="border-white mt-6 hover:text-primary hover:bg-white border flex items-center justify-center px-4 py-2 rounded-lg B1 font-semibold ">
            Contact us
            <ArrowRight className="ml-1 w-4 h-5" />
          </Link>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}
