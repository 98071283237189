import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';
import { ReactComponent as Doctor } from '../images/svg/doctor.svg';
import { ReactComponent as Green } from '../images/svg/green.svg';
import { ReactComponent as Blue } from '../images/svg/blue.svg';
import { Button } from '../components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight,  } from 'lucide-react';
import G1 from '../images/new/g1.png';
import G2 from '../images/new/g2.png';
import G3 from '../images/new/g3.png';
import G4 from '../images/new/g4.png';

import G6 from '../images/new/g6.png';
import G9 from '../images/new/g9.png';
import G7 from '../images/new/g7.png';
import G8 from '../images/new/g8.png';
import G5 from '../images/new/g5.png';

import WhyUs from '../images/new/whyUs.png';
import Accordion from '../components/Accordion';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CarouselImg1 from '../images/new/carousel.png';
import CarouselImg2 from '../images/new/carousel2.png';
import CarouselImg3 from '../images/new/carousel3.png';

export default function Main() {

  const navigate = useNavigate();
  
  const slider=[{
    title:'Physiotherapy & General Osteopathy',
    img:CarouselImg1,
  },{
    title:'Logotherapy & Biodecoding',
    img:CarouselImg2,
  },{
    title:`Women's Health`,
    img:CarouselImg3,
  }];
  
  return (
    <>
      <MenuBar />
      <div
        className="bg-main flex justify-center items-center sm:mt-px sm:h-[708px] h-[300px] "
        style={{ backgroundSize: '100% 100%' }}
      >
        <div className="font-bold text-4xl bottom-20  sm:text-9xl sm:left-6 relative sm:bottom-40 text-primary">
          Move
        </div>
        <div className="font-bold text-4xl bottom-10 sm:text-9xl relative sm:bottom-20 text-bold_secondary">
          Heal
        </div>
        <div className="font-bold text-4xl  sm:text-9xl sm:right-6  relative text-[#FFD556] ">
          Thrive
        </div>
      </div>
      <div className="text-center py-16 px-10 sm:px-0">
        <div className="D1 font-bold">
          <span className="text-bold_secondary">Feel your Motions, </span>
          <span className="text-primary">Move your Emotions</span>
        </div>
      </div>

      <Carousel
        autoPlay
        emulateTouch
        
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <li
            onClick={clickHandler}
            className={`sm:h-5 h-2 w-2 cursor-pointer transition-all inline-block rounded-full sm:w-5  m-1 ${
              isSelected ? 'bg-bold_secondary' : 'bg-[#ECECEC]'
            }`}
          ></li>
        )}
        infiniteLoop
        showThumbs={false}
      >
        {slider.map((record,index)=><div key={index} className="relative">
          <div className="responsive-D1  sm:w-2/5  text-left  text-xl font-bold text-bold_secondary absolute  left-[10%] sm:top-[30%] top-[20%]">
            {record.title}
          </div>
          <div className="absolute left-[10%] top-1/2">
            <Button onClick={()=>navigate('services')} className="sm:h-12 h-8  text-xs responsive-B1 font-semibold" variant="outline">
              Learn More
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
          <img src={record.img} />
        </div>)}
       
      </Carousel>

      <div className="grid mt-20  sm:grid-cols-4 grid-cols-2   max-w-7xl m-auto px-10 sm:px-0">
        <div className="p-3">
          <div className="flex items-center">
            <Bone />
            <div className="px-2 text-[11px] responsive-B1 font-semibold text-primary">CHECK OUR CLINIC</div>
          </div>
          <div className="responsive-D1 text-2xl pt-1 font-bold text-bold_secondary">OUR GALLERY</div>
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G1} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G2} />
        </div>
        <div className="row-span-2 p-3">
          <img className="w-full h-full" src={G3} />
        </div>
        <div className="row-span-2 p-3">
          <img className="w-full h-full" src={G4} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G5} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G6} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G7} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G8} />
        </div>
        <div className="p-3">
          <img className="w-full h-full" src={G9} />
        </div>
      </div>
      <div className="bg-[#E7F3F2]  mt-20">
        <div className="flex sm:flex-nowrap max-w-7xl flex-wrap justify-between sm:py-20 pb-20  m-auto  ">
          <img src={WhyUs} />
          <div className="sm:pl-10 sm:px-0 px-10 pt-20 sm:pt-0">
            <div className="flex pb-1">
              <Bone />
              <div className="B1 font-semibold px-2 text-primary">OUR ADVANTAGES</div>
            </div>
            <div className="D1 font-bold text-bold_secondary pb-2">Why Choose Us?</div>
            <div className="B2 ">
              At Motion Emotion, we offer a unique blend of expertise and compassion.
              <br /> Here is why choosing us ensures an unparalleled healthcare experience:
            </div>
            <div className="grid grid-cols-2 pt-8">
              <div className="py-2">
                <div className="H3 font-bold text-bold_secondary pb-2">Holistic Integration</div>
                <div className="B2">
                  We are not just a medical center; we are a hub where physiotherapists, therapists,
                  coaches, and medical doctors work collaboratively to address your comprehensive
                  health needs. Our integrated approach ensures that you receive holistic care that
                  considers every aspect of your well-being.
                </div>
              </div>

              <div className="p-2">
                <div className="H3 font-bold text-bold_secondary pb-2">Diverse and Expert Team</div>
                <div className="B2">
                  Motion Emotion is home to a diverse and dedicated team of professionals, each
                  bringing unique expertise to the table. From highly skilled physiotherapists to
                  empathetic coaches and experienced medical doctors, our team collaborates
                  seamlessly to provide you with well-rounded care.
                </div>
              </div>

              <div className="py-2">
                <div className="H3 font-bold text-bold_secondary pb-2">
                  Patient-Centric Personalization
                </div>
                <div className="B2">
                  Your health journey is unique, and so are you. Our experienced team members take
                  the time to understand your individual needs, crafting personalized treatment
                  plans that align with your specific health goals.
                </div>
              </div>

              <div className="p-2">
                <div className="H3 font-bold text-bold_secondary pb-2">Cutting-Edge Innovation</div>
                <div className="B2">
                  Our commitment to innovation ensures that you have access to the latest
                  developments in all our specialized services, offering you the most effective and
                  up-to-date care options.
                </div>
              </div>

              <div className="py-2">
                <div className="H3 font-bold text-bold_secondary pb-2">
                  Empowering Wellness Culture
                </div>
                <div className="B2">
                  Beyond treating symptoms, we are committed to empowering you on your journey to
                  wellness. Through education, support, and a culture of empowerment, we aim to
                  equip you with the knowledge and tools necessary to actively participate in and
                  manage your health effectively.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative'>
      <Green className='absolute top-1/3 z-[-10]' />
      <Blue className='absolute bottom-28 right-0 z-[-10]' />
      <div className="m-auto py-20 max-w-3xl px-10 sm:px-0 ">
        
        <div className="pb-6">
          <div className="flex pb-1">
            <Bone />
            <div className="B1 font-semibold px-2 text-primary">HAVE MORE QUESTIONS?</div>
          </div>
          <div className="text-bold_secondary D1 font-bold">FAQs</div>
        </div>
        <Accordion />
        <div className="B1">
          If you have any additional questions or require further clarification, feel free to
          contact our friendly staff, who will be happy to assist you.
        </div>
      </div>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white max-w-7xl m-auto">
        <div className="sm:ml-auto order-2 sm:order-1 pt-10 sm:pt-0">
          <Doctor className="relative sm:left-5 px-8 sm:px-0 w-5/6 m-auto sm:m-0 sm:w-auto" />
        </div>
        <div className="sm:h-[54%] order-1 sm:order-2 sm:w-2/3 mt-auto mb-7 bg-primary py-16 pt-11 flex flex-col justify-center items-center">
          <div className="H3 text-center max-w-xs font-semibold mt-5">
          &lsquo;Health is a state of complete physical, mental and social well-being.&rsquo;
          <br/>
          (World Health Organization)
          </div>
          <div className="H3 font-semibold mt-6">Contact our experts</div>
          <Link to={'/contact-us'} className="border-white mt-6 hover:text-primary hover:bg-white border flex items-center justify-center px-4 py-2 rounded-lg B1 font-semibold ">
            Contact us
            <ArrowRight className="ml-1 w-4 h-5" />
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
