import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // If you're using React Router v6

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when pathname changes
  }, [pathname]);

  return null; // Since this component doesn't render anything, return null
}

export default ScrollToTopOnMount;
