import React from 'react';
import Footer from '../navigation/Footer';
import MenuBar from '../navigation/Menu';
import { ReactComponent as Bone } from '../images/svg/bone.svg';
import { ReactComponent as Phone } from '../images/svg/phone.svg';
import { ReactComponent as Location } from '../images/svg/location.svg';
import { ReactComponent as Email } from '../images/svg/email.svg';

import { ReactComponent as Career } from '../images/svg/career.svg';

import { ReactComponent as SmallGreen } from '../images/svg/smallGreen.svg';
import { ReactComponent as Green } from '../images/svg/green.svg';



import ContactForm from '../components/ContactForm';

export default function Contact() {
  return (
    <>
      <MenuBar />
      <div className="bg-contactUs hidden sm:block mt-px h-[448px]" style={{ backgroundSize: '100% 100%' }}></div>
      <div className='relative'>
        <Green   className='z-[-10] absolute right-56 top-1/2 '/>
        <SmallGreen className='z-[-10] top-32 absolute transform scale-x-[-1]' />
      <div className="px-10 sm:px-0">
        <div
          className="max-w-3xl relative  -bottom-12 sm:bottom-12 mx-auto bg-white rounded-lg"
          style={{
            boxShadow: '1px 2px 4px 1px #00000026'
          }}
        >
          <div className="sm:px-24 sm:py-14 p-10">
            <div>
              <div className="flex">
                <Bone />
                <div className="B1 font-semibold text-primary">NEED ANY HELP?</div>
              </div>
              <div className="H1 font-semibold pt-2 text-bold_secondary">Get in touch with us</div>
          <div className='pt-8'>
            <ContactForm />
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="max-w-7xl mx-auto sm:px-0 px-10 ">
        <div className="H1 font-semibold text-bold_secondary">Find us Here</div>
        <div className="grid sm:grid-flow-col    py-8  ">

          <div className="flex py-2 sm:py-0  items-center ">
            <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Phone className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Any Questions? Call us</div>
              <div className="H3 font-semibold text-primary">+961 70 216 356</div>
            </div>
          </div>
          <div className='bg-[#DDD8DB] hidden sm:block w-px'></div>
          <div className="flex py-2 sm:py-0 items-center    ">
            <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Email className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Any Questions? Email us</div>
              <div className="H3 font-semibold text-primary">info@motionemotion.clinic</div>
            </div>
          </div>
          <div className='bg-[#DDD8DB] hidden sm:block  w-px'></div>
          <div className="flex py-2 sm:py-0  sm:items-center   items-start">
            <div className="bg-[#ECECEC] p-3 rounded-lg mr-5">
              <Location className='w-6 h-6' />
            </div>
            <div>
              <div className="B1 text-color_909090">Ghazir, Kfarebab bifurcation</div>
              <div className="H3 font-semibold text-primary">
                GF, Mirza Bld, Facing KMC hospital
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="bg-maps h-[448px] hidden sm:block" style={{ backgroundSize: '100% 100%' }}></div>

      <div className="grid px-10 sm:px-0 sm:grid-cols-2 grid-cols-1 sm:py-24 py-10 text-white max-w-3xl mx-auto ">
        <div className="mx-auto sm:mx-0 order-2 sm:order-1 pt-10 sm:pt-0">
          <Career className="px-8 sm:px-0" />
        </div>

        <div className="order-1 m-auto  sm:order-2 ">

          <div className="flex">
            <Bone />
            <div className="B1 font-semibold text-primary">CAREER</div>
          </div>
          <div className="H2 font-bold mt-2 text-bold_secondary">
            Are you looking to join our team?
          </div>
          <div className="mt-8">
            <div className="flex ">
              <div className=" bg-[#ECECEC] p-3 rounded-lg mr-5">
                <Email />
              </div>
              <div>
                <div className="B1 text-color_909090">Send us your resume on</div>
                <div className="sm:text-xl text-lg font-semibold text-primary ">career@motionemotion.clinic</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
